:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
}

.styles-module__dU_25G__wrapper {
  position: relative;
}

.styles-module__dU_25G__fontSize20 {
  font-size: 20px;
}

.styles-module__dU_25G__sliderItem {
  width: 90%;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

@media (min-width: 768px) {
  .styles-module__dU_25G__sliderItem {
    width: 60%;
  }
}

.styles-module__dU_25G__title {
  background: linear-gradient(0deg, var(--wcom-c-blueDark), var(--wcom-c-blue));
  color: var(--wcom-c-white);
  text-align: center;
  border-radius: 3px 3px 0 0;
  padding: 10px;
}

.styles-module__dU_25G__description {
  font-size: 15px;
  line-height: 23px;
}

.styles-module__dU_25G__mapWrapper {
  position: relative;
}

.styles-module__dU_25G__mapLink {
  display: block;
}

.styles-module__dU_25G__mapImg {
  width: 100%;
  height: 100%;
  display: block;
}

.styles-module__dU_25G__readMore {
  color: var(--wcom-c-blueDark);
  text-align: right;
  margin-top: 25px;
  display: block;
}

.styles-module__dU_25G__readMoreIcon {
  margin-left: 10px;
}

.styles-module__dU_25G__readMoreIcon:before {
  font-size: 10px;
  display: inline-block;
  transform: rotate(270deg);
}

